.container {
  display: flex;
  justify-content: space-between;
  /* margin-top: -0.5%; */
  height: 92vh;
  border-top: 1px solid black;
}

.formSection {
  width: 52.5%;
  padding: 2vw;
  background: linear-gradient(180deg, #f6f3ff 0%, #afa7d8 100%);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.label {
  color: #000;

  font-family: Inter;
  font-size: 1.35vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
}
.line {
  border: none;
  height: 1px;
  background-color: #000;
  width: 100%;
  /* padding-top: 0%; */
  margin-top: 0%;
}
.heading {
  color: #000;

  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3vw;
}
.heading1 {
  color: #000;

  font-family: Inter;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3vw;
  margin-top: -10%;
  margin-bottom: 5%;
}

h2 {
  margin-bottom: 20px;
  color: #4a4a4a;
  font-size: 1.5vw;
}

.formGroup {
  position: relative;
  margin-bottom: 1.7vw;
}

.formGroup input,
.formGroup select {
  margin-top: 1.5%;
  width: 95.5%;
  /* height: 49px; */
  height: 49px;
  padding: 0.75vw;
  border-radius: 0.5vw;
  border: 1px #2f4156;

  background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);

  box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
    4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  font-size: 1vw;
}

.formGroup select {
  appearance: none;
  /* height: 50px; */
}
.formGroup input::placeholder {
  color: #b5b3b3;

  text-align: justify;
  font-family: Inter;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.customSelect::after {
  content: url("Down_Button.png");
  font-size: 1.5vw;
  position: absolute;
  right: 8%;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  top: 33%;

  transform: translateY(40%);
  pointer-events: none; /* Prevent the image from capturing clicks */

}

.unit {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(40%);

  color: #312070;

  text-align: justify;
  font-family: Inter;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.captureSection {
  width: 45%;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.captureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cameraFrame {
  width: 400px;
  height: 400px; /* Ensure width and height are equal to maintain the circular shape */
  border-radius: 50%; /* Makes the frame circular */
  overflow: hidden; /* Ensures content doesn't spill out */
  border: 2px solid #ccc; /* Optional: Add a border for visual enhancement */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000; /* Optional: Add a background color if needed */
}

.cameraFeed {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the frame without distortion */
}

.captureButton {
  margin-top: 10%;
  width: 10.5vw;
  height: 3.3vw;
  font-size: 1vw;
  background: #d7d2f4;

  border: none;
  border-radius: 0.2vw;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #312070;

  text-align: center;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2vw;
}

.captureButton:hover {
  background-color: #9b89f2;
}

.verticalLine {
  position: absolute;
  /* Line width */
  position: absolute;
  right: 10vw;
  height: 58%;
  width: 1px;
  background-color: #000;
  top: 42%;
}

@media screen and (max-width: 912px) {
  .container {
    height: 100%;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(180deg, #f6f3ff 0%, #afa7d8 100%);
    /* border-top: 1px solid black; */
  }
  .line {
    margin-top: 30px;
  }
  .formSection {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 94.2%;
    background: transparent;
    padding-right: 2.4vw;
  }
  .heading {
    color: #101215;

    text-align: center;
    font-family: Inter;
    font-size: 2.8vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    /* margin-top: -35%; */
  }
  .formGroup {
    display: block;
    unicode-bidi: isolate;
  }

  .formGroup input {
    margin-top: 2%;
    /* width: 100%;
    height: 4vw; */
    width: 102%;
    height: 5.2vw;
    font-size: 2vw;
    border-radius: 8px;
    border: 1px #2f4156;

    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);

    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  }
  .formGroup select {
    width: 102%;
    height: 5.2vw;
    font-size: 2vw;
    border-radius: 8px;
    border: 1px #2f4156;

    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);

    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  }
  .customSelect::after {
    right: 4%;
    top: 41%;
  }

  .label {
    font-size: 3vw;
  }

  .formGroup {
    margin-bottom: 5vw;
    margin-left: 3%;
  }
  .verticalLine {
    width: 1px;
    height: 42px;
    right: 68px;
    top: 50%;
  }
  .captureSection {
    width: 100%;
  }

  .heading1 {
    margin-top: 0%;
    font-size: 5vw;
    color: #2f4156;
  }
  .cameraFrame {
    width: 40vw;
    height: 40vw;
  }
  .captureButton {
    margin-top: 5%;
    width: 25vw;
    height: 8vw;
    background: #d7d2f4;

    border: 1px solid #312070;
    border-radius: 8px;

    font-size: 3vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    color: #312070;
  }
  .formGroup input::placeholder {
    font-size: 2.5vw;
  }
  /* .verticalLine {
  display: none;
} */
  .unit {
    right: 1%;
    color: #312070;

    text-align: justify;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media screen and (max-width: 768px) {
  .container {
    height: 100%;
    flex-direction: column;
    background: linear-gradient(180deg, #f6f3ff 0%, #afa7d8 100%);
  }
  .line {
    margin-top: 20px;
  }
  .formSection {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 94%;
    background: transparent;
    padding: 2vw;
  }

  .formGroup {
    height: 17vw;
  }

  .heading {
    color: #101215;

    text-align: center;
    font-family: Inter;
    font-size: 2.8vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    /* margin-top: -35%; */
  }

  .formGroup input {
    margin-top: 2%;
    width: 100%;
    height: 10vw !important;
    font-size: 3vw;
    border-radius: 8px;
    border: 1px #2f4156;

    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);

    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  }
  .formGroup select {
    width: 100%;
    height: 10vw;
    font-size: 3vw;
    border-radius: 8px;
    border: 1px #2f4156;

    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);

    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  }
  .customSelect::after {
    right: 2%;
    top: 24%;
  }

  .label {
    font-size: 5vw;
  }

  .formGroup {
    margin-bottom: 5vw;
    margin-left: 3%;
  }
  .verticalLine {
    width: 1px;
    height: 39px;
    right: 62px;
    top: 42%;
  }
  .captureSection {
    width: 100%;
  }

  .heading1 {
    margin-top: 0%;
    font-size: 7vw;
    color: #2f4156;
  }
  .cameraFrame {
    width: 80vw; /* Reduce width to 80% of the viewport width */
    height: 80vw; /* Adjust height proportionally */
  }
  .captureButton {
    margin-top: 5%;
    width: 40vw;
    height: 15vw;
    background: #d7d2f4;

    border: 1px solid #312070;
    border-radius: 8px;

    font-size: 6vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    color: #312070;
  }
  .formGroup input::placeholder {
    font-size: 2.5vw;
  }
  /* .verticalLine {
display: none;
} */
  .unit {
    right: 1%;
    color: #312070;

    text-align: justify;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
