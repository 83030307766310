.container {
  /* margin: 20px; */
  height: fit-content;
  /* background-color: red; */
}
.firstsection{
  height: fit-content;
  /* background-color: aqua; */
  /* background-color: greenyellow; */
}
.heading {
  color: #2f4156;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.9px;
  padding-left: 25px;
  padding-bottom: 15px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  table-layout: fixed; /* Ensures columns adhere to set widths */
}
.table th:nth-child(1), /* Name column */
.table td:nth-child(1) {
  width: 21%;
}
.table th:nth-child(2), /* Age column */
.table td:nth-child(2) {
  width: 18.8%;
}

.table th:nth-child(3), /* Gender column */
.table td:nth-child(3) {
  width: 19%;
}

.table th,
.table td {
  padding: 9px;
  text-align: center;
  border: 1.5px solid #d7d2f4;
}

.table th {
  font-weight: 600;
  color: #333;
}

.table td {
  color: #555;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.table1 {
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
.table1 th,
.table1 td {
  padding: 9px;
  text-align: center;
  border: 1.5px solid #d7d2f4;
  background: #fff;
}

.table1 th {
  font-weight: 600;
  color: #333;
}

.table1 td {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.24px;
  color: #555;
  background: #fff;
  width: 20%;
  height: 1%;
  flex-shrink: 0;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.25);
}

.table1 tr:hover {
  background-color: #f1f1f1;
}

.insidecontainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
  flex-direction: row;
  /* height: 32rem; */
  height: 65vh;
  width:100%
}

.rescon {
  /* flex: 1; */
  margin-right: 20px;
  width: 59%;
  padding-left: 2%;
  /* background-color: red; */
  /* background-color: green; */
}

.modelimg {
  display: flex;
  position: relative;
  width: 40%;
  justify-content: center;
  /* border: 1px solid black; */
}

.modelimg img {
  height: 100%;
  /* width: 64%; */
  /* margin-top: 10px; */
}
.neck {
  position: absolute; /* Position the SVG absolutely within the .modelimg container */
  top: -33%;
  left: -35.2%;
  width: 47.5px;
  height: 2.789px; /* Scale to match the height of the image */
  pointer-events: none; /* Prevent SVG from interfering with user interactions */
}
.chest {
  position: absolute;
  width: 100px;
  height: 9px;
  flex-shrink: 0;
  top: -23%;
  left: 31.5%;
}
.waist {
  position: absolute;
  width: 85px;
  height: 5.522px;
  flex-shrink: 0;
  left: 32.5%;
  top: -11%;
  stroke-width: 1px;
}
.hip {
  position: absolute;
  width: 95px;
  height: 5.522px;
  flex-shrink: 0;
  left: 32%;
  top: -1%;
  stroke-width: 1px;
}
.shoulder {
  position: absolute;
  height: auto; /* Position the SVG absolutely within the .modelimg container */

  width: 154px;
  pointer-events: none; /* Prevent SVG from interfering with user interactions */
}
.mobcontainer {
  display: none;
}
.toggleButton {
  font-family: Arial, Helvetica, sans-serif !important;
  background-color: #e9e6e6;
  border: 1px solid #363636;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 5px;
  margin-left: 10px;
  font-size: 0.8em;
  letter-spacing: normal !important;
  font-weight: 400 !important;
  /* color: #007bff !important; */
}

/* Style for the active state of the toggle button */
.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.Button {
  /* margin-top: -2%; */
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: #555; */
}

.downloadButton {
  background: #d7d2f4;
  color: #312070;
  /* margin-top: 1%; */
  /* padding: 7px 30px; */
  border: none;
  border-radius: 0.5rem;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* margin-left: 0.8rem; */
  /* margin-left: 27%; */
}

.downloadButton:hover {
  background-color: #312070;
  color: white;
}

@media screen and (max-width: 912px) {
  .container {
    display: none;
  }
  .mobcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f3ff;
    padding: 20px;
    padding-bottom: 0.5rem;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }

  .heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2f4156;

    text-align: center;
    font-family: Inter;
  }

  .con1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .inputRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }

  .inputBox {
    flex: 1;
    min-width: 320px !important;
    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);
    border-radius: 6px;
    padding: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    text-align: center;
    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  }
  .inputBox1 {
    /* flex: 1; */
    min-width: 120px;
    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .inputField {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  .inputField1 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }

  .modelimg {
    position: relative; /* Ensure the container is positioned relative to place the SVG on top */

    margin: 20px 0;
    justify-content: center;
    /* margin-left: -200px; */
  }
  .modelimg img {
    width: 100%;
    /* margin-top: 10px; */
  }

  .image {
    width: 500px;
    height: auto;
    display: block;
    margin: 0;
  }

  .rescon {
    width: 100%;
    margin: 20px 0;
  }

  .table1 {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .table1 th,
  .table1 td {
    padding: 10px;
    text-align: center;
    color: #000;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.24px;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.25);

    color: #333;
  }

  .table1 th {
    background-color: #e0e0ff;
    color: #555;
  }

  .downloadButton {
    height: 3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #d7d2f4;
    color: #312070;
    /* padding: 0 1rem; */
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .downloadButton:hover {
    background-color: #312070;
    color: white;
  }
  .rescon{
    padding-left: 0px;
  }
}


@media screen and (max-width: 768px) {
  .container {
    display: none;
  }
  .mobcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f3ff;
    padding: 20px;
    padding-bottom: 0.5rem;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }

  .heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #2f4156;

    text-align: center;
    font-family: Inter;
  }

  .con1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .inputRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }

  .inputBox {
    flex: 1;
    /* min-width: 120px !important; */
    min-width: 45% !important;
    height: 60px !important;
    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);
    border-radius: 6px;
    padding: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    text-align: center;
    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
  }
  .inputBox1 {
    /* flex: 1; */
    min-width: 120px;
    height: 60px !important;
    background: linear-gradient(180deg, #d6d0f6 0%, #fff 100%);
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25),
      4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
  .inputField {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  .inputField1 {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }

  .modelimg {
    width: 100% !important;
    padding: 0 2.1rem;
    margin: 20px 0;
    text-align: left;
    /* margin-left: -150px; */
    justify-content: center;
  }

  .image {
    width: auto;
    height: 100%;
    display: block;
    margin: 0;
  }

  .rescon {
    width: 100%;
    margin: 20px 0;
  }

  .table1 {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .table1 th,
  .table1 td {
    padding: 10px;
    text-align: center;
    color: #000;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.24px;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.25);

    color: #333;
  }

  .table1 th {
    background-color: #e0e0ff;
    color: #555;
  }

  .downloadButton {
    background-color: #d7d2f4;
    color: #312070;
    /* padding: 1rem 1rem; */
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .downloadButton:hover {
    background-color: #312070;
    color: white;
  }
  .toggleButton {
    font-size: 0.8em;
    padding: 3px 7px;
    font-family: 'Arial' !important;
  }

  .unitCell {
    justify-content: flex-end;
  }
}


/* @media screen and (max-width: 480px) {
  .inputBox {
    min-width: 45% !important;
  }
} */