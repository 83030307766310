.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    /* padding-top: 10px; */
    width: 100%;
    /* height: 8vh; */
    height: 3.6rem;
    /* border: 1px solid black; */
  }
  
  .logo {
    display: none !important;
    height: 100%;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    /* border: 1px solid black; */
  }
  
  .logo2 {
    height: 100%;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    /* border: 1px solid black; */
  }

  img {
    max-width: fit-content;
  }

  .imglogo {
    width: 110px !important;
    /* border: 1px solid black; */
    /* padding: 2vw 0; */
    /* height: fit-content; */
  }
  
  .hamburger {
    display: none;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .navLinks {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    gap: 5%;
    transition: transform 0.3s ease-in-out;
    /* margin-block-start: 1em;
    margin-block-end: 1em; */
    unicode-bidi: isolate;
  }
  
  .navLink {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2F4156;
    padding: 6px 0px;
    border-radius: 7px;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: Inter;
    font-weight: 400;
    /* width: 130px; */
    width: 9vw;
  }
  
  /* Hide icons by default */
  .navLink i {
    display: none;
    margin-right: 10px; /* Space between icon and text */
  }
  
  .navLink:hover {
    background-color: #2F4156;
    color: white;
    transition: .3s all ease-in-out;
  }
  .activeLink {
    background-color: #2F4156;
    color: white;


  }
  .navName{
    display: none;
  }
 
  
  /* Mobile Styles */
  @media screen and (max-width: 912px) {
    .navbar {
      justify-content: space-between;
      height: fit-content;
    }
  
    .hamburger {
      display: flex;
      order: 1;
      font-size: 3vh;

    }
  
    .logo {
      display: none;
    }

    .logo2 {
      display: flex;
      order: 2;
      margin-left: auto;
    }
  
    .navLinks {
      position: absolute;
      top: 4%;
      left: 0%;
      height: fit-content;
      width: 240px;
      flex-direction: column;
      background-color: #fff;
      padding: 20px 30px;
      transform: translateX(-100%);
      gap: 10px;
      z-index: 20;
    }
    
    .navLinks.showMenu {
      transform: translateX(0%);
    }
  
    .navLink {
      padding: 15px 10px;
      font-size: 1rem;
      text-align: center;
      width: 15vh;
      height: 3.738vh;
      justify-content: left;

    }
  
    /* Show icons only on mobile */
    .navLink i {
      display: inline-block;
    }
    .activeLink {
      /* Specific styling for active link in mobile view */
      display: inline-flex; /* Ensure it's visible */
    }
    .navName{
      display: block;
      
    }
    .imglogo {
      height:25px;
      /* width:fit-content; */
      width: auto;
    }
  }

  @media screen and (max-width: 768px) {
    .navLinks {
      top: 3.5%;
      left: -1.5%;
      height: fit-content;
      width: 190px;
      padding: 20px;
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .navLinks li a {
      padding-left: 0.75rem;
      width: 100%;
    }

    .navName{
      padding-left: 10px;
    }
  }