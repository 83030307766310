.container{
    display: flex;
  justify-content: center;
  /* margin-top: -0.5%; */
  background: linear-gradient(180deg, #F6F3FF 0%, #AFA7D8 100%);
  height: 92vh;
  width:100%


}
.heading{
    color: #000;

font-family: Inter;
font-size: 35px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 5.6px;
text-align: center;
}
.formSection {
    padding: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .formgroup{
    display: flex;
    flex-direction: column;
    margin-top: 2%;

  }
  .label{
    color: #000;

font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 3.2px;
text-align: center;
  }
  .inputemail{
    width: 708px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
border: 1px solid #2F4156;

background: linear-gradient(180deg, #D6D0F6 0%, #FFF 100%);

box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
margin-top: 3%;
margin-bottom: 5%;
font-size: 1vw;
text-align: center;


  }
  .inputmessage{
    width: 708px;
    height: 247px;
    flex-shrink: 0;
    border-radius: 8px;
border: 1px solid #2F4156;

background: linear-gradient(0deg, #FFF 0%, #D6D0F6 100%);

box-shadow: 7px 6px 10.9px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 10.6px 0px rgba(0, 0, 0, 0.25);
margin-top: 3%;
font-size: 2.5vw;
text-align: center;
margin-bottom: 5%;

  }
  .submit{
    width: 179px;
height: 35px;
flex-shrink: 0;
border-radius: 12px;
background: #2F4156;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(2px);
color: #FFF;

text-align: justify;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 7.82px;
text-align: center;
  }
  .btn{
    display: flex;
    justify-content: center;
    padding: 1.2vw;
  }
  @media screen and (max-width: 912px) {
    .formSection {
        margin-top: 15%;
    }
    .inputmessage{
        width: 500px;
        margin-bottom: 10%;
        margin-top: 5%;

        font-size: 5vw;


    }
    .heading{
    font-size: 45px;
    }
    .formSection {
        padding: 50px;
    }
    .inputemail{
        width:500px;
        margin-bottom: 10%;
        margin-top: 5%;
        font-size: 4vw;


    }
    .btn{
        margin-top: 10%;
    }
   .label{
    font-size: 30px;
   }
   .submit{
    width: 189px;
height: 45px;
   }
    
  }
  @media screen and (max-width: 768px) {
    .container {
      width: 100%;
      
    }
    .inputmessage{
        width: 300px;
        margin-bottom: 10%;
        margin-top: 5%;

        font-size: 5vw;


    }
    .heading{
    font-size: 25px;
    }
    .formSection {
        padding: 50px;
    }
    .inputemail{
        width:300px;
        margin-bottom: 10%;
        margin-top: 5%;
        font-size: 4vw;


    }
    .btn{
        margin-top: 3%;
    }
    .formSection {
        margin-top: 10%;
    }

}