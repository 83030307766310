/* Navbar.module.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    padding-top: 10px;
    height: 2.9vw;
  }
  
  .logo {
    font-size: 1.5rem;
    color: #2F4156;
    font-weight: 400;
    font-family: Inter;

 
  }
  
  .navLinks {
    display: flex;
    list-style: none;
    gap: 10vw;
    margin-left: -2%;
    
  }
  
  .navLink {
    color: #2F4156;
    padding: 10px 20px;
    border-radius: 7px;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
  }
  
  .navLink:hover {
    background-color:  #2F4156;;
    color: white;
  }
  